import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { CreateFinePage } from './create-fine.page';
import {CurrencyMaskModule} from 'ng2-currency-mask';

const routes: Routes = [
  {
    path: '',
    component: CreateFinePage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    CurrencyMaskModule
  ],
  declarations: [CreateFinePage]
})
export class CreateFinePageModule {}
