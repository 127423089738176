import { Component, OnInit } from '@angular/core';
import {Role} from '../models/roles.enum';
import {NavParams, PopoverController} from '@ionic/angular';
import {User} from '../models/user';
import {UserRole} from '../models/role';
import {Roles} from '../models/roles';

@Component({
  selector: 'app-user-role-popover',
  templateUrl: './user-role-popover.page.html',
  styleUrls: ['./user-role-popover.page.scss'],
})
export class UserRolePopoverPage implements OnInit {

  role = Role;
  roleList: UserRole[] = [];
  user: User;

  constructor(private navParams: NavParams, private popoverCtrl: PopoverController) { }

  ngOnInit() {
    this.user = this.navParams.get('user');
    this.fillRoleList();
  }

  fillRoleList() {
      const list = Object.keys(this.role);
      // Check for each userRole whether it is assigned to the user
      list.forEach(role => {
          let isUserRole = false;
          this.user.roleList.forEach(userRole => {
              if (role.toLowerCase() === userRole.role) {
                  isUserRole = true;
              }
          });
          this.roleList.push({role: Role[role], hasRole: isUserRole});
      });
  }

    setRole(userRole: UserRole) {
        this.user.roleList = [];
        const roles = {} as Roles;
        roles.admin = true;
        this.user.roles = roles;
        this.user.roleList.push({role: userRole.role, hasRole: true});
        this.popoverCtrl.dismiss({user: this.user});
        // Sollte ein User mehrere Rollen haben dürfen, könnte die folgende Implementirung interessant werden
        /*let roleExists = false;
        this.user.roleList.forEach(role => {
          if(role.role.toString()==userRole.role.toString()){
            roleExists = true;
            const index = this.user.roleList.indexOf(role, 0);
            if (index > -1) {
              this.user.roleList.splice(index, 1);
            }
          }
        });
        if(!roleExists){
          this.user.roleList.push({role:userRole.role, hasRole:true})
        }*/
    }

}
