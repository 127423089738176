import { Component, OnInit } from '@angular/core';
import {Fine} from '../models/fine';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../services/constants';
import {ModalController, NavParams} from '@ionic/angular';
import {FineProvider} from '../services/fine/fine';

@Component({
  selector: 'app-create-fine',
  templateUrl: './create-fine.page.html',
  styleUrls: ['./create-fine.page.scss'],
})
export class CreateFinePage implements OnInit {

    formGroup: FormGroup;

    fine: Fine;

    constructor(private formBuilder: FormBuilder, private modalCtrl: ModalController, private navParams: NavParams,
                private fineProvider: FineProvider) { }

  ngOnInit() {
      this.formGroup = this.formBuilder.group({
          description: ['', Validators.compose([Validators.pattern(Constants.onlyLettersRegEx), Validators.maxLength(20),
              Validators.required])],
          value: [0, Validators.min(0.1)],
      }, {
          updateOn: 'blur'
      });
      this.fine = this.navParams.get('fine');
      if (!!this.fine && !!this.fine.id) {
        this.formGroup.setValue({
            description: this.fine.description,
            value: this.fine.value
        });
      }
  }

    addFine() {
        const fine: Fine = {description: this.formGroup.get('description').value, value: this.formGroup.get('value').value};
        if (!!this.fine && !!this.fine.id) {
          fine.id = this.fine.id;
          console.log('update');
          this.fineProvider.updateFine(fine);
        } else {
          this.fineProvider.addFine(fine);
        }
        this.modalCtrl.dismiss();
    }

    cancel() {
        this.modalCtrl.dismiss();
    }

}
