import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController, NavParams} from '@ionic/angular';
import {AngularFirestore} from 'angularfire2/firestore';
import {take} from 'rxjs/operators';
import {KegelAbendProvider} from '../services/kegel-abend/kegel-abend';
import {AlertProvider} from '../services/alert/alert';

@Component({
  selector: 'app-delete-kegel-abend-confirmation',
  templateUrl: './delete-kegel-abend-confirmation.page.html',
  styleUrls: ['./delete-kegel-abend-confirmation.page.scss'],
})
export class DeleteKegelAbendConfirmationPage implements OnInit {

  formGroup: FormGroup;

  kegelAbendId: string;

  constructor(private formBuilder: FormBuilder, private navParams: NavParams, private afs: AngularFirestore,
              private kegelAbendProvider: KegelAbendProvider, private modalCtrl: ModalController, private alertProvider: AlertProvider) { }

  ngOnInit() {
      this.kegelAbendId = this.navParams.get('id');
      this.formGroup = this.formBuilder.group({
          password: ['', Validators.compose([Validators.minLength(20), Validators.maxLength(20), Validators.required])]
      }, {
          updateOn: 'blur'
      });
  }

    cancel() {
        this.modalCtrl.dismiss();
    }

  async deleteKegelAbend() {
      const comparisonPassword = await this.afs.collection('comparisonPassword', ref =>
          ref.where('password', '==', this.formGroup.get('password').value))
          .valueChanges()
          .pipe(take(1))
          .toPromise();
      if (comparisonPassword.length > 0 ) {
          this.kegelAbendProvider.deleteKegelAbend(this.kegelAbendId);
          this.modalCtrl.dismiss();
      } else {
          this.alertProvider.toastMessage('Falsches Passwort eingegeben');
      }
  }

}
